body {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
}

hr {
  text-align: center;
  margin: 1em auto;
  opacity: 0.5;
}

ul {
  text-transform: uppercase;
}

ul.inline-list.right {
  text-transform: none;
}

ul.inline-list.right a {
  color: #000;
}

ul.inline-list.right a:hover {
  color: rgba(0, 0, 0, 0.5);
}

.button:focus,
.button:active {
  background-color: transparent !important;
}

h1 {
  font-family: 'VT323', 'Courier New', Courier, Monaco, mono, serif;
  font-size: 32px;
  color: #fff;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-align: center;
}

h2 {
  font-family: 'VT323', 'Courier New', Courier, Monaco, mono, serif;
  color: #333;
  font-size: 36px;
  line-height: 36px;
}

.about-box h2 {
  color: #ffffff;

}

h3 {
  font-family: 'VT323', 'Courier New', Courier, Monaco, mono, serif;
  color: #c0c0c0;
  font-size: 28px;
  line-height: 28px;
  text-align: left;
}

h4 {
  font-family: 'VT323', 'Courier New', Courier, Monaco, mono, serif;
  color: #000;
  font-size: 28px;
  padding-top: 5px;
}

h5 {
  font-size: 13px;
  color: #c0c0c0;
}

a {
  color: #000000;
}

a:hover {
  color: #666666;
}

h1 img.logo {
  margin: .5em 0;
  max-width: 200px;
}

.columns.large-centered {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 40em) {
  .columns.large-centered {
    font-size: 13px;
  }
}

@media only screen and (min-width: 40.063em) {
  .columns.large-centered {
    font-size: 14px;
  }
}

.about-box {
  color: #fff;
}

.black-box {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 35px;
  font-weight: 900;
}

.black-box p {
  font-weight: 900;
}

.secondary.small.button {
  background-color: transparent;
  color: #fff;
  border: 2px #fff solid;
  border-radius: 5px;
  padding: 15px 50px 15px 50px;
  margin: 0;
}

.secondary.small.button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.row .main-wrapper {
  color: #fff;
}

.about-content {
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.button-group li .button {
  border: 0 !important;
}

.button {
  -webkit-appearance: none;
  background-color: transparent;
  border-width: 0;
  text-align: center;
  box-shadow: 0 0 0;
}

.button:hover {
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent;
}

footer p, footer a {
  font-size: 14px;
}

.hero {
  padding-bottom: 50px;
  background-image: url("../images/achtergrond.jpg");
  background-size: cover;
  box-shadow: inset 0px 0px 0 2000px rgba(0, 0, 0, 0.3);
}

.alt-backgr {
  background-image: url("../images/achtergrond-alt.jpg");
}

.alt-backgr2 {
  background-image: url("../images/achtergrond-alt2.jpg");
}

.wenneker-content {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #EAEAEA;
}

.panel {
  margin: 40px 0 40px 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: #EAEAEA;
}

.large-4 img {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.radius.right.button {
  color: #000;
  border: 2px #000 solid;
  border-radius: 5px;
  padding: 15px 50px 15px 50px;
  margin: 0;
  font-size: 0.8125em;
}

ul.organisaties li a img {
  border: 1px solid #ffffff;
}

ul.organisaties li a:hover img {
  border: 1px solid #333333;
}

@media only screen and (min-width: 64.063em) {
  .radius.right.button {
    color: #000;
    border: 2px #000 solid;
    border-radius: 5px;
    padding: 15px 50px 15px 50px;
    margin-top: 25px;
    font-size: 0.8125em;
  }
}

@media only screen and (min-width: 40.063em) {
  .radius.right.button {
    color: #000;
    border: 2px #000 solid;
    border-radius: 5px;
    padding: 15px 50px 15px 50px;
    margin-top: 25px;
    font-size: 0.8125em;
  }
}

.radius.right.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

section.footer {
  padding: 1em 0;
}

.white {
  background-color: #ffffff;
}

.lightgrey {
  background-color: #eeebeb;
}

.searchbuttoncolor {
  background: #cccccc;
}

.button-width {
  width: 100%;
}

li a.button.color01 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #93c04e !important;
  border: 2px #93c04e solid !important;
}

li a.button.color02 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #d0487a !important;
  border: 2px #d0487a solid !important;
}

li a.button.color03 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #d0487a !important;
  border: 2px #d0487a solid !important;
}

li a.button.color04 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #4072b9 !important;
  border: 2px #4072b9 solid !important;
}

li a.button.color05 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #cb232a !important;
  border: 2px #cb232a solid !important;
}

li a.button.color06 {
  font-family: VT323, 'Courier New', Courier, Monaco, mono;
  font-size: 2em;
  color: #644e9a !important;
  border: 2px #644e9a solid !important;
}

.button.wenneker-button {
  color: #000 !important;
  border: 2px #000 solid !important;
}

/*--------------------*/

.breadcrumbs > *:before {
  content: "»";
}

nav ul.right li a {
  font-size: 1.1em;
}

.breadcrumbs a:link, a:visited {
  color: #d0487a;
}